import { isArray, isObject } from "lodash";
import moment from "moment";

export const formatJobtypes = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
    };
  }
};

export const formatAbsences = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
      start: data.start && moment(data.start),
      end: data.end && moment(data.end),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
      start: data.start && moment(data.start),
      end: data.end && moment(data.end),
    };
  }
};
export const formatUsers = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name} ${data.surname}`,
      value: data._id,
      last_login: data.last_login && moment(data.last_login),
      createdAt: data.createdAt && moment(data.createdAt),
      licenseDate: data.licenseDate && moment(data.licenseDate),
      deleted_At: data.deleted_At && moment(data.deleted_At),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name} ${data.surname}`,
      last_login: data.last_login && moment(data.last_login),
      deleted_At: data.deleted_At && moment(data.deleted_At),
      createdAt: data.createdAt && moment(data.createdAt),
      licenseDate: data.licenseDate && moment(data.licenseDate),
    };
  }
};

export const formatHours = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      label: `${data.name}`,
      value: data._id,
      ...data,
      startDate: data?.startDate ? moment(data?.startDate) : "",
      endDate: data?.endDate ? moment(data?.endDate) : "",
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      startDate: data?.startDate ? moment(data?.startDate) : "",
      endDate: data?.endDate ? moment(data?.endDate) : "",
    };
  }
};

export const formatMaintenances = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
      date: data.date && moment(data.date),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
      date: data.date && moment(data.date),
    };
  }
};

export const formatNavUsers = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.Name}`,
      value: data.No,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      label: `${data.Name}`,
      value: data.No,
    };
  }
};

export const formatEpis = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.Name}`,
      value: data._id,
      date: data.date && moment(data.date),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      label: `${data.Name}`,
      value: data._id,
      date: data.date && moment(data.date),
    };
  }
};

export const formatProducts = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      label: `${data.description}`,
      value: data._id,
    };
  }
};

export const formatDosificationProduct = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      label: `${data.name}`,
      value: data._id,
    };
  }
};

export const formatDosificationConfig = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data._id}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      label: `${data._id}`,
      value: data._id,
    };
  }
};

export const formatEstablishmentInfo = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.Name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      label: `${data.Name}`,
      value: data._id,
    };
  }
};

export const formatEquipmentConfigurations = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
    };
  }
};

export const formatInstalations = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};

export const formatSecuritySheets = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};

export const formatShopCarts = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      date: data.date && moment(data.date),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      date: data.date && moment(data.date),
    };
  }
};

export const formatArticles = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data.Item_No,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data.Item_No,
      label: `${data.name}`,
    };
  }
};

export const formatRepartidors = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.Name}`,
      value: data.No,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data.No,
      label: `${data.Name}`,
    };
  }
};

export const formatRepartiments = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.Name}`,
      value: data.No,
      Document_Date: data.Document_Date && moment(data.Document_Date),
      Fecha_Reparto: data.Fecha_Reparto && moment(data.Fecha_Reparto),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data.No,
      label: `${data.Name}`,
      Document_Date: data.Document_Date && moment(data.Document_Date),
      Fecha_Reparto: data.Fecha_Reparto && moment(data.Fecha_Reparto),
    };
  }
};

export const formatInvoices = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    let noFilters = data.map((data) => ({
      ...data,
      label: `${data.Bill_to_Name}`,
      value: data.No,
      Document_Date: data.Document_Date && moment(data.Document_Date),
    }));
    noFilters = noFilters.filter(
      (x) => x.Document_Date >= moment().add(-1, "y")
    );
    return noFilters;
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data.No,
      label: `${data.Bill_to_Name}`,
      Document_Date: data.Document_Date && moment(data.Document_Date),
    };
  }
};

export const formatSatisfactions = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      date: data.date && moment(data.date),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      date: data.date && moment(data.date),
    };
  }
};

export const formatEquipments = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      lastCommunicate: data.lastCommunicate && moment(data.lastCommunicate),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      lastCommunicate: data.lastCommunicate && moment(data.lastCommunicate),
    };
  }
};

export const formatIncidences = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
      start: data.start && moment(data.start),
      predictedend: data.predictedend && moment(data.predictedend),
      end: data.end && moment(data.end),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      label: `${data.description}`,
      value: data._id,
      start: data.start && moment(data.start),
      predictedend: data.predictedend && moment(data.predictedend),
      end: data.end && moment(data.end),
    };
  }
};

export const formatProjects = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};

export const formatImputHours = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      end: data.end && moment(data.end),
      start: data.start && moment(data.start),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      end: data.end && moment(data.end),
      start: data.start && moment(data.start),
    };
  }
};

export const formatTimeRequests = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      checkIn: data.checkIn && moment(data.checkIn),
      breakOut: data.breakOut && moment(data.breakOut),
      breakIn: data.breakIn && moment(data.breakIn),
      checkOut: data.checkOut && moment(data.checkOut),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      checkIn: data.checkIn && moment(data.checkIn),
      breakOut: data.breakOut && moment(data.breakOut),
      breakIn: data.breakIn && moment(data.breakIn),
      checkOut: data.checkOut && moment(data.checkOut),
    };
  }
};

export const formatHolidayRequests = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      end: data.end && moment(data.end),
      start: data.start && moment(data.start),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      end: data.end && moment(data.end),
      start: data.start && moment(data.start),
    };
  }
};

export const formatSingUps = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      checkIn: data.checkIn && moment(data.checkIn),
      breakOut: data.breakOut && moment(data.breakOut),
      breakIn: data.breakIn && moment(data.breakIn),
      checkOut: data.checkOut && moment(data.checkOut),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      checkIn: data.checkIn && moment(data.checkIn),
      breakOut: data.breakOut && moment(data.breakOut),
      breakIn: data.breakIn && moment(data.breakIn),
      checkOut: data.checkOut && moment(data.checkOut),
    };
  }
};

export const formatSuggestions = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      end: data.end && moment(data.end),
      start: data.start && moment(data.start),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      end: data.end && moment(data.end),
      start: data.start && moment(data.start),
    };
  }
};

export const formatWorkOrders = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.key}`,
      value: data._id,
      date: data.date && moment(data.date),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.key}`,
      date: data.date && moment(data.date),
    };
  }
};

export const formatBottomFertilizers = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.key}`,
      value: data._id,
      date: data.date && moment(data.date),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.key}`,
      date: data.date && moment(data.date),
    };
  }
};

export const formatPhytosanitarys = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.key}`,
      value: data._id,
      date: data.date && moment(data.date),
      expirationDate: data.expirationDate && moment(data.expirationDate),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.key}`,
      date: data.date && moment(data.date),
      expirationDate: data.expirationDate && moment(data.expirationDate),
    };
  }
};

export const formatTags = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
    };
  }
};

export const formatDosifications = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
      date: data.date && moment(data.date),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
      date: data.date && moment(data.date),
    };
  }
};

export const formatToolTypes = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
    };
  }
};

export const formatPlagues = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
    };
  }
};

export const formatCommercialBrands = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};

export const formatTasktypes = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
    };
  }
};

export const formatProviders = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};

export const formatReplacements = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
    };
  }
};

export const formatReasons = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
    };
  }
};

export const formatRefuelings = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
      date: data.date && moment(data.date),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
      date: data.date && moment(data.date),
    };
  }
};

export const formatFertigations = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
      end: data.end && moment(data.end),
      start: data.start && moment(data.start),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
      end: data.end && moment(data.end),
      start: data.start && moment(data.start),
    };
  }
};

export const formatProductTypes = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
    };
  }
};

export const formatFamilys = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
    };
  }
};

export const formatVarietys = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
    };
  }
};

export const formatCrops = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
    };
  }
};

export const formatWorks = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
      end: data.end && moment(data.end),
      start: data.start && moment(data.start),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
      end: data.end && moment(data.end),
      start: data.start && moment(data.start),
    };
  }
};

export const formatTasks = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
    };
  }
};

export const formatAdvisers = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};

export const formatSeeds = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
    };
  }
};

export const formatTools = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
      dischargedDate: data.dischargedDate && moment(data.dischargedDate),
      acquisitionDate: data.acquisitionDate && moment(data.acquisitionDate),
      dateLastInspection: data.dateLastInspection && moment(data.dateLastInspection),
      disabledDate: data.disabledDate && moment(data.disabledDate),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
      dischargedDate: data.dischargedDate && moment(data.dischargedDate),
      acquisitionDate: data.acquisitionDate && moment(data.acquisitionDate),
      dateLastInspection: data.dateLastInspection && moment(data.dateLastInspection),
      disabledDate: data.disabledDate && moment(data.disabledDate),
    };
  }
};

export const formatExploitations = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
      createdAt: data.createdAt && moment(data.createdAt),
      start: data.start && moment(data.start),
      end: data.end && moment(data.end),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
      createdAt: data.createdAt && moment(data.createdAt),
      start: data.start && moment(data.start),
      end: data.end && moment(data.end),
    };
  }
};

export const formatPlots = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
      createdAt: data.createdAt && moment(data.createdAt),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
      createdAt: data.createdAt && moment(data.createdAt),
    };
  }
};

export const formatEnclosures = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
      createdAt: data.createdAt && moment(data.createdAt),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
      createdAt: data.createdAt && moment(data.createdAt),
    };
  }
};

export const formatRoles = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
    };
  }
};

export const formatWorkShift = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
      end: data.end && moment(data.end),
      start: data.start && moment(data.start),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
      end: data.end && moment(data.end),
      start: data.start && moment(data.start),
    };
  }
};

export const formatLeave = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
      end: data.end && moment(data.end),
      start: data.start && moment(data.start),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
      end: data.end && moment(data.end),
      start: data.start && moment(data.start),
    };
  }
};

export const formatOPCUA = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.description}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.description}`,
    };
  }
};

export const formatExpenses = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      date: moment(data.date || data.createdAt)
        .set("s", 0)
        .set("ms", 0),
      end: data.end && moment(data.end).set("s", 0).set("ms", 0),
      dateFormat: moment(data.date || data.createdAt).format("LL"),
      hours: data.hours.map((hour) => ({
        start: moment(hour.start).set("s", 0).set("ms", 0),
        end: moment(hour.end).set("s", 0).set("ms", 0),
      })),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      label: `${data.name}`,
      value: data._id,
      date:
        data.date &&
        moment(data.date || data.createdAt)
          .set("s", 0)
          .set("ms", 0),
      end: data.end && moment(data.end).set("s", 0).set("ms", 0),
      dateFormat: moment(data.date || data.createdAt).format("LL"),
      hours: data.hours.map((hour) => ({
        start: moment(hour.start).set("s", 0).set("ms", 0),
        end: moment(hour.end).set("s", 0).set("ms", 0),
      })),
    };
  }
};

export const formatAlerts = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      label: `${data.title}`,
      value: data._id,
      ...data,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.title}`,
    };
  }
};

export const formatDownloadDocuments = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      label: `${data.title}`,
      value: data._id,
      ...data,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.title}`,
    };
  }
};
