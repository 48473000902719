import { FinishedTaskIcon, InProcessTaskIcon, PendingTaskIcon } from "./icons";

/* API */
export const MAPS_API = "AIzaSyDqm62_JJvnvLK6ob9UKY1oQ9LTx0ul_VA";

/* BASIC APP CONSTANTS */
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const APP_COOKIE_NAME = "APP_COOKIE_NAME";
export const USER_COOKIE_NAME = "USER_COOKIE_NAME";
export const PASS_COOKIE_NAME = "PASS_COOKIE_NAME";
export const USER_ID_COOKIE_NAME = "USER_ID_COOKIE_NAME";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_SIDE_MENU_STATE = "SET_SIDE_MENU_STATE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SHOW_ACTION_MENU = "SHOW_ACTION_MENU";
export const HIDE_ACTION_MENU = "HIDE_ACTION_MENU";
export const SHOW_BOTTOM_TOOLBAR = "SHOW_BOTTOM_TOOLBAR";
export const HIDE_BOTTOM_TOOLBAR = "HIDE_BOTTOM_TOOLBAR";
export const SET_USER_LIST = "SET_USER_LIST";
export const SET_NAVUSER_LIST = "SET_NAVUSER_LIST";
export const SET_PRODUCTS_LIST = "SET_PRODUCTS_LIST";
export const EDIT_DISABLED_BUTTONS = "EDIT_DISABLED_BUTTONS";
export const SET_LOGS_LIST = "SET_LOGS_LIST";
export const THEME = "THEME";

/* <---- BASIC APP CONSTANTS ----> */



/* ABSENCE */
export const SET_ABSENCE_LIST = "SET_ABSENCE_LIST";
export const ADD_NEW_ABSENCE = "ADD_NEW_ABSENCE";
export const UPDATE_ABSENCE = "UPDATE_ABSENCE";
export const DELETE_ABSENCE = "DELETE_ABSENCE";
export const OPEN_ABSENCE_FORM = "OPEN_ABSENCE_FORM";
export const CLOSE_ABSENCE_FORM = "CLOSE_ABSENCE_FORM";
export const EDIT_SELECTED_ABSENCE = "EDIT_SELECTED_ABSENCE";
export const ABSENCE_FORM_TOOGLE_LOADING = "ABSENCE_FORM_TOOGLE_LOADING";
/* <---- ABSENCE ----> */

/* SOCKET CONSTANTS */
export const CONNECTED = "connected";
export const DISCONNECTED = "disconnected";
/* <---- SOCKET CONSTANTS ----> */

/* HOURS */
export const SET_HOURS = "SET_HOURS";
export const ADD_NEW_HOURS = "ADD_NEW_HOURS";
export const HOURS_FORM_TOOGLE_LOADING = "HOURS_FORM_TOOGLE_LOADING";
export const UPDATE_HOURS = "UPDATE_HOURS";
export const DELETE_HOURS = "DELETE_HOURS";
export const OPEN_HOURS_FORM = "OPEN_HOURS_FORM";
export const CLOSE_HOURS_FORM = "CLOSE_HOURS_FORM";
export const EDIT_SELECTED_HOURS = "EDIT_SELECTED_HOURS";
export const VIEW_HOURS = "VIEW_HOURS";
export const CLOSE_VIEW_HOURS = "CLOSE_VIEW_HOURS";
/* <---- HOURS ----> */

/* CONFIGURATION */
export const CONFIGURATION_FORM_TOOGLE_LOADING =
  "CONFIGURATION_FORM_TOOGLE_LOADING";
export const SET_CONFIGURATION = "SET_CONFIGURATION";
/* <---- CONFIGURATION ----> */

/* USER */
export const SET_PROFILE = "SET_PROFILE";
/* <---- USER ----> */

/* ESTABLISHMENT */
export const SET_ESTABLISHMENT_INFO = "SET_ESTABLISHMENT_INFO";
export const UPDATE_ESTABLISHMENT_INFO = "UPDATE_ESTABLISHMENT_INFO";
export const SET_ESTABLISHMENT_SETTINGS = "SET_ESTABLISHMENT_SETTINGS";
export const UPDATE_ESTABLISHMENT_SETTINGS = "UPDATE_ESTABLISHMENT_SETTINGS";
/* <---- ESTABLISHMENT ----> */

/* USERS */
export const ADD_NEW_USER = "ADD_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const OPEN_USERS_FORM = "OPEN_USERS_FORM";
export const CLOSE_USERS_FORM = "CLOSE_USERS_FORM";
export const EDIT_SELECTED_USER = "EDIT_SELECTED_USER";
export const USERS_FORM_TOOGLE_LOADING = "USERS_FORM_TOOGLE_LOADING";
/* <---- USERS ----> */

/* JOBTYPE */
export const SET_JOBTYPE_LIST = "SET_JOBTYPE_LIST";
export const ADD_NEW_JOBTYPE = "ADD_NEW_JOBTYPE";
export const UPDATE_JOBTYPE = "UPDATE_JOBTYPE";
export const DELETE_JOBTYPE = "DELETE_JOBTYPE";
export const OPEN_JOBTYPE_FORM = "OPEN_JOBTYPE_FORM";
export const CLOSE_JOBTYPE_FORM = "CLOSE_JOBTYPE_FORM";
export const EDIT_SELECTED_JOBTYPE = "EDIT_SELECTED_JOBTYPE";
export const JOBTYPE_FORM_TOOGLE_LOADING = "JOBTYPE_FORM_TOOGLE_LOADING";
/* <---- JOBTYPE ----> */

/* ROUTES */
export const BUYS = "shopCarts";
export const INSTALATIONS = "instalations";
export const CONFIGURATION = "configuration";
export const PROJECTS = "projects";
/* <---- ROUTES ----> */

/* EQUIPMENTCONFIGURATION */
export const SET_EQUIPMENTCONFIGURATION_LIST =
  "SET_EQUIPMENTCONFIGURATION_LIST";
export const ADD_NEW_EQUIPMENTCONFIGURATION = "ADD_NEW_EQUIPMENTCONFIGURATION";
export const UPDATE_EQUIPMENTCONFIGURATION = "UPDATE_EQUIPMENTCONFIGURATION";
export const DELETE_EQUIPMENTCONFIGURATION = "DELETE_EQUIPMENTCONFIGURATION";
export const OPEN_EQUIPMENTCONFIGURATION_FORM =
  "OPEN_EQUIPMENTCONFIGURATION_FORM";
export const CLOSE_EQUIPMENTCONFIGURATION_FORM =
  "CLOSE_EQUIPMENTCONFIGURATION_FORM";
export const EDIT_SELECTED_EQUIPMENTCONFIGURATION =
  "EDIT_SELECTED_EQUIPMENTCONFIGURATION";
export const EQUIPMENTCONFIGURATION_FORM_TOOGLE_LOADING =
  "EQUIPMENTCONFIGURATION_FORM_TOOGLE_LOADING";
/* <---- EQUIPMENTCONFIGURATION ----> */

/* INSTALATION */
export const SET_INSTALATION_LIST = "SET_INSTALATION_LIST";
export const ADD_NEW_INSTALATION = "ADD_NEW_INSTALATION";
export const UPDATE_INSTALATION = "UPDATE_INSTALATION";
export const DELETE_INSTALATION = "DELETE_INSTALATION";
export const OPEN_INSTALATION_FORM = "OPEN_INSTALATION_FORM";
export const CLOSE_INSTALATION_FORM = "CLOSE_INSTALATION_FORM";
export const EDIT_SELECTED_INSTALATION = "EDIT_SELECTED_INSTALATION";
export const SET_ALLINSTALATION_LIST = "SET_ALLINSTALATION_LIST";
export const INSTALATION_FORM_TOOGLE_LOADING =
  "INSTALATION_FORM_TOOGLE_LOADING";
/* <---- INSTALATION ----> */

/* SECURITYSHEET */
export const SET_SECURITYSHEET_LIST = "SET_SECURITYSHEET_LIST";
export const ADD_NEW_SECURITYSHEET = "ADD_NEW_SECURITYSHEET";
export const UPDATE_SECURITYSHEET = "UPDATE_SECURITYSHEET";
export const DELETE_SECURITYSHEET = "DELETE_SECURITYSHEET";
export const OPEN_SECURITYSHEET_FORM = "OPEN_SECURITYSHEET_FORM";
export const CLOSE_SECURITYSHEET_FORM = "CLOSE_SECURITYSHEET_FORM";
export const EDIT_SELECTED_SECURITYSHEET = "EDIT_SELECTED_SECURITYSHEET";
export const SECURITYSHEET_FORM_TOOGLE_LOADING =
  "SECURITYSHEET_FORM_TOOGLE_LOADING";
/* <---- SECURITYSHEET ----> */

/* SHOPCARTLINE */
export const SET_SHOPCARTLINE_LIST = "SET_SHOPCARTLINE_LIST";
export const ADD_NEW_SHOPCARTLINE = "ADD_NEW_SHOPCARTLINE";
export const UPDATE_SHOPCARTLINE = "UPDATE_SHOPCARTLINE";
export const DELETE_SHOPCARTLINE = "DELETE_SHOPCARTLINE";
export const OPEN_SHOPCARTLINE_FORM = "OPEN_SHOPCARTLINE_FORM";
export const CLOSE_SHOPCARTLINE_FORM = "CLOSE_SHOPCARTLINE_FORM";
export const EDIT_SELECTED_SHOPCARTLINE = "EDIT_SELECTED_SHOPCARTLINE";
export const SHOPCARTLINE_FORM_TOOGLE_LOADING =
  "SHOPCARTLINE_FORM_TOOGLE_LOADING";
/* <---- SHOPCARTLINE ----> */

/* SHOPCART */
export const SET_SHOPCART_LIST = "SET_SHOPCART_LIST";
export const SET_ARTICLES_LIST = "SET_ARTICLES_LIST";
export const SET_REPARTIDORS_LIST = "SET_REPARTIDORS_LIST";
export const SET_REPARTIMENTS_LIST = "SET_REPARTIMENTS_LIST";
export const SET_DELIVERYNOTES_LIST = "SET_DELIVERYNOTES_LIST";
export const SET_INVOICES_LIST = "SET_INVOICES_LIST";
export const ADD_NEW_SHOPCART = "ADD_NEW_SHOPCART";
export const UPDATE_SHOPCART = "UPDATE_SHOPCART";
export const DELETE_SHOPCART = "DELETE_SHOPCART";
export const OPEN_SHOPCART_FORM = "OPEN_SHOPCART_FORM";
export const CLOSE_SHOPCART_FORM = "CLOSE_SHOPCART_FORM";
export const EDIT_SELECTED_SHOPCART = "EDIT_SELECTED_SHOPCART";
export const SHOPCART_FORM_TOOGLE_LOADING = "SHOPCART_FORM_TOOGLE_LOADING";
/* <---- SHOPCART ----> */

/* SATISFACTION */
export const SET_SATISFACTION_LIST = "SET_SATISFACTION_LIST";
export const ADD_NEW_SATISFACTION = "ADD_NEW_SATISFACTION";
export const UPDATE_SATISFACTION = "UPDATE_SATISFACTION";
export const DELETE_SATISFACTION = "DELETE_SATISFACTION";
export const OPEN_SATISFACTION_FORM = "OPEN_SATISFACTION_FORM";
export const CLOSE_SATISFACTION_FORM = "CLOSE_SATISFACTION_FORM";
export const EDIT_SELECTED_SATISFACTION = "EDIT_SELECTED_SATISFACTION";
export const SATISFACTION_FORM_TOOGLE_LOADING =
  "SATISFACTION_FORM_TOOGLE_LOADING";
/* <---- SATISFACTION ----> */

/* INCIDENCE */
export const SET_INCIDENCE_LIST = "SET_INCIDENCE_LIST";
export const ADD_NEW_INCIDENCE = "ADD_NEW_INCIDENCE";
export const UPDATE_INCIDENCE = "UPDATE_INCIDENCE";
export const DELETE_INCIDENCE = "DELETE_INCIDENCE";
export const OPEN_INCIDENCE_FORM = "OPEN_INCIDENCE_FORM";
export const CLOSE_INCIDENCE_FORM = "CLOSE_INCIDENCE_FORM";
export const EDIT_SELECTED_INCIDENCE = "EDIT_SELECTED_INCIDENCE";
export const INCIDENCE_FORM_TOOGLE_LOADING = "INCIDENCE_FORM_TOOGLE_LOADING";
/* <---- INCIDENCE ----> */

/* EQUIPMENT */
export const SET_EQUIPMENT_LIST = "SET_EQUIPMENT_LIST";
export const ADD_NEW_EQUIPMENT = "ADD_NEW_EQUIPMENT";
export const UPDATE_EQUIPMENT = "UPDATE_EQUIPMENT";
export const DELETE_EQUIPMENT = "DELETE_EQUIPMENT";
export const OPEN_EQUIPMENT_FORM = "OPEN_EQUIPMENT_FORM";
export const CLOSE_EQUIPMENT_FORM = "CLOSE_EQUIPMENT_FORM";
export const EDIT_SELECTED_EQUIPMENT = "EDIT_SELECTED_EQUIPMENT";
export const EQUIPMENT_FORM_TOOGLE_LOADING = "EQUIPMENT_FORM_TOOGLE_LOADING";
export const SET_ALLEQUIPMENT_LIST = "SET_ALLEQUIPMENT_LIST";
/* <---- EQUIPMENT ----> */

/* MAINTENANCE */
export const SET_MAINTENANCE_LIST = "SET_MAINTENANCE_LIST";
export const ADD_NEW_MAINTENANCE = "ADD_NEW_MAINTENANCE";
export const UPDATE_MAINTENANCE = "UPDATE_MAINTENANCE";
export const DELETE_MAINTENANCE = "DELETE_MAINTENANCE";
export const OPEN_MAINTENANCE_FORM = "OPEN_MAINTENANCE_FORM";
export const CLOSE_MAINTENANCE_FORM = "CLOSE_MAINTENANCE_FORM";
export const EDIT_SELECTED_MAINTENANCE = "EDIT_SELECTED_MAINTENANCE";
export const MAINTENANCE_FORM_TOOGLE_LOADING =
  "MAINTENANCE_FORM_TOOGLE_LOADING";
/* <---- MAINTENANCE ----> */

/* SINGUP */
export const SET_SINGUP_LIST = "SET_SINGUP_LIST";
export const ADD_NEW_SINGUP = "ADD_NEW_SINGUP";
export const UPDATE_SINGUP = "UPDATE_SINGUP";
export const DELETE_SINGUP = "DELETE_SINGUP";
export const OPEN_SINGUP_FORM = "OPEN_SINGUP_FORM";
export const CLOSE_SINGUP_FORM = "CLOSE_SINGUP_FORM";
export const EDIT_SELECTED_SINGUP = "EDIT_SELECTED_SINGUP";
export const SINGUP_FORM_TOOGLE_LOADING = "SINGUP_FORM_TOOGLE_LOADING";
/* <---- SINGUP ----> */

/* PROJECT */
export const SET_PROJECT_LIST = "SET_PROJECT_LIST";
export const ADD_NEW_PROJECT = "ADD_NEW_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const OPEN_PROJECT_FORM = "OPEN_PROJECT_FORM";
export const CLOSE_PROJECT_FORM = "CLOSE_PROJECT_FORM";
export const EDIT_SELECTED_PROJECT = "EDIT_SELECTED_PROJECT";
export const PROJECT_FORM_TOOGLE_LOADING = "PROJECT_FORM_TOOGLE_LOADING";
/* <---- PROJECT ----> */

/* SUGGESTION */
export const SET_SUGGESTION_LIST = "SET_SUGGESTION_LIST";
export const ADD_NEW_SUGGESTION = "ADD_NEW_SUGGESTION";
export const UPDATE_SUGGESTION = "UPDATE_SUGGESTION";
export const DELETE_SUGGESTION = "DELETE_SUGGESTION";
export const OPEN_SUGGESTION_FORM = "OPEN_SUGGESTION_FORM";
export const CLOSE_SUGGESTION_FORM = "CLOSE_SUGGESTION_FORM";
export const EDIT_SELECTED_SUGGESTION = "EDIT_SELECTED_SUGGESTION";
export const SUGGESTION_FORM_TOOGLE_LOADING = "SUGGESTION_FORM_TOOGLE_LOADING";
/* <---- SUGGESTION ----> */

/* WORKORDER */
export const SET_WORKORDER_LIST = "SET_WORKORDER_LIST";
export const ADD_NEW_WORKORDER = "ADD_NEW_WORKORDER";
export const UPDATE_WORKORDER = "UPDATE_WORKORDER";
export const DELETE_WORKORDER = "DELETE_WORKORDER";
export const OPEN_WORKORDER_FORM = "OPEN_WORKORDER_FORM";
export const CLOSE_WORKORDER_FORM = "CLOSE_WORKORDER_FORM";
export const EDIT_SELECTED_WORKORDER = "EDIT_SELECTED_WORKORDER";
export const WORKORDER_FORM_TOOGLE_LOADING = "WORKORDER_FORM_TOOGLE_LOADING";
/* <---- WORKORDER ----> */

/* BOTTOMFERTILIZER */
export const SET_BOTTOMFERTILIZER_LIST = "SET_BOTTOMFERTILIZER_LIST";
export const ADD_NEW_BOTTOMFERTILIZER = "ADD_NEW_BOTTOMFERTILIZER";
export const UPDATE_BOTTOMFERTILIZER = "UPDATE_BOTTOMFERTILIZER";
export const DELETE_BOTTOMFERTILIZER = "DELETE_BOTTOMFERTILIZER";
export const OPEN_BOTTOMFERTILIZER_FORM = "OPEN_BOTTOMFERTILIZER_FORM";
export const CLOSE_BOTTOMFERTILIZER_FORM = "CLOSE_BOTTOMFERTILIZER_FORM";
export const EDIT_SELECTED_BOTTOMFERTILIZER = "EDIT_SELECTED_BOTTOMFERTILIZER";
export const BOTTOMFERTILIZER_FORM_TOOGLE_LOADING = "BOTTOMFERTILIZER_FORM_TOOGLE_LOADING";
/* <---- BOTTOMFERTILIZER ----> */

/* PHYTOSANITARY */
export const SET_PHYTOSANITARY_LIST = "SET_PHYTOSANITARY_LIST";
export const ADD_NEW_PHYTOSANITARY = "ADD_NEW_PHYTOSANITARY";
export const UPDATE_PHYTOSANITARY = "UPDATE_PHYTOSANITARY";
export const DELETE_PHYTOSANITARY = "DELETE_PHYTOSANITARY";
export const OPEN_PHYTOSANITARY_FORM = "OPEN_PHYTOSANITARY_FORM";
export const CLOSE_PHYTOSANITARY_FORM = "CLOSE_PHYTOSANITARY_FORM";
export const EDIT_SELECTED_PHYTOSANITARY = "EDIT_SELECTED_PHYTOSANITARY";
export const PHYTOSANITARY_FORM_TOOGLE_LOADING = "PHYTOSANITARY_FORM_TOOGLE_LOADING";
/* <---- PHYTOSANITARY ----> */

/* TAG */
export const SET_TAG_LIST = "SET_TAG_LIST";
export const ADD_NEW_TAG = "ADD_NEW_TAG";
export const UPDATE_TAG = "UPDATE_TAG";
export const DELETE_TAG = "DELETE_TAG";
export const OPEN_TAG_FORM = "OPEN_TAG_FORM";
export const CLOSE_TAG_FORM = "CLOSE_TAG_FORM";
export const EDIT_SELECTED_TAG = "EDIT_SELECTED_TAG";
export const TAG_FORM_TOOGLE_LOADING = "TAG_FORM_TOOGLE_LOADING";
/* <---- TAG ----> */

/* DOSIFICATION */
export const SET_DOSIFICATION_LIST = "SET_DOSIFICATION_LIST";
export const ADD_NEW_DOSIFICATION = "ADD_NEW_DOSIFICATION";
export const UPDATE_DOSIFICATION = "UPDATE_DOSIFICATION";
export const DELETE_DOSIFICATION = "DELETE_DOSIFICATION";
export const OPEN_DOSIFICATION_FORM = "OPEN_DOSIFICATION_FORM";
export const CLOSE_DOSIFICATION_FORM = "CLOSE_DOSIFICATION_FORM";
export const EDIT_SELECTED_DOSIFICATION = "EDIT_SELECTED_DOSIFICATION";
export const DOSIFICATION_FORM_TOOGLE_LOADING = "DOSIFICATION_FORM_TOOGLE_LOADING";
/* <---- DOSIFICATION ----> */

/* REASON */
export const SET_REASON_LIST = "SET_REASON_LIST";
export const ADD_NEW_REASON = "ADD_NEW_REASON";
export const UPDATE_REASON = "UPDATE_REASON";
export const DELETE_REASON = "DELETE_REASON";
export const OPEN_REASON_FORM = "OPEN_REASON_FORM";
export const CLOSE_REASON_FORM = "CLOSE_REASON_FORM";
export const EDIT_SELECTED_REASON = "EDIT_SELECTED_REASON";
export const REASON_FORM_TOOGLE_LOADING = "REASON_FORM_TOOGLE_LOADING";
/* <---- REASON ----> */

/* REFUELING */
export const SET_REFUELING_LIST = "SET_REFUELING_LIST";
export const ADD_NEW_REFUELING = "ADD_NEW_REFUELING";
export const UPDATE_REFUELING = "UPDATE_REFUELING";
export const DELETE_REFUELING = "DELETE_REFUELING";
export const OPEN_REFUELING_FORM = "OPEN_REFUELING_FORM";
export const CLOSE_REFUELING_FORM = "CLOSE_REFUELING_FORM";
export const EDIT_SELECTED_REFUELING = "EDIT_SELECTED_REFUELING";
export const REFUELING_FORM_TOOGLE_LOADING = "REFUELING_FORM_TOOGLE_LOADING";
/* <---- REFUELING ----> */

/* FERTIGATION */
export const SET_FERTIGATION_LIST = "SET_FERTIGATION_LIST";
export const ADD_NEW_FERTIGATION = "ADD_NEW_FERTIGATION";
export const UPDATE_FERTIGATION = "UPDATE_FERTIGATION";
export const DELETE_FERTIGATION = "DELETE_FERTIGATION";
export const OPEN_FERTIGATION_FORM = "OPEN_FERTIGATION_FORM";
export const CLOSE_FERTIGATION_FORM = "CLOSE_FERTIGATION_FORM";
export const EDIT_SELECTED_FERTIGATION = "EDIT_SELECTED_FERTIGATION";
export const FERTIGATION_FORM_TOOGLE_LOADING = "FERTIGATION_FORM_TOOGLE_LOADING";
/* <---- FERTIGATION ----> */

/* TOOLTYPE */
export const SET_TOOLTYPE_LIST = "SET_TOOLTYPE_LIST";
export const ADD_NEW_TOOLTYPE = "ADD_NEW_TOOLTYPE";
export const UPDATE_TOOLTYPE = "UPDATE_TOOLTYPE";
export const DELETE_TOOLTYPE = "DELETE_TOOLTYPE";
export const OPEN_TOOLTYPE_FORM = "OPEN_TOOLTYPE_FORM";
export const CLOSE_TOOLTYPE_FORM = "CLOSE_TOOLTYPE_FORM";
export const EDIT_SELECTED_TOOLTYPE = "EDIT_SELECTED_TOOLTYPE";
export const TOOLTYPE_FORM_TOOGLE_LOADING = "TOOLTYPE_FORM_TOOGLE_LOADING";
/* <---- TOOLTYPE ----> */

/* PLAGUE */
export const SET_PLAGUE_LIST = "SET_PLAGUE_LIST";
export const ADD_NEW_PLAGUE = "ADD_NEW_PLAGUE";
export const UPDATE_PLAGUE = "UPDATE_PLAGUE";
export const DELETE_PLAGUE = "DELETE_PLAGUE";
export const OPEN_PLAGUE_FORM = "OPEN_PLAGUE_FORM";
export const CLOSE_PLAGUE_FORM = "CLOSE_PLAGUE_FORM";
export const EDIT_SELECTED_PLAGUE = "EDIT_SELECTED_PLAGUE";
export const PLAGUE_FORM_TOOGLE_LOADING = "PLAGUE_FORM_TOOGLE_LOADING";
/* <---- PLAGUE ----> */

/* COMMERCIALBRAND */
export const SET_COMMERCIALBRAND_LIST = "SET_COMMERCIALBRAND_LIST";
export const ADD_NEW_COMMERCIALBRAND = "ADD_NEW_COMMERCIALBRAND";
export const UPDATE_COMMERCIALBRAND = "UPDATE_COMMERCIALBRAND";
export const DELETE_COMMERCIALBRAND = "DELETE_COMMERCIALBRAND";
export const OPEN_COMMERCIALBRAND_FORM = "OPEN_COMMERCIALBRAND_FORM";
export const CLOSE_COMMERCIALBRAND_FORM = "CLOSE_COMMERCIALBRAND_FORM";
export const EDIT_SELECTED_COMMERCIALBRAND = "EDIT_SELECTED_COMMERCIALBRAND";
export const COMMERCIALBRAND_FORM_TOOGLE_LOADING = "COMMERCIALBRAND_FORM_TOOGLE_LOADING";
/* <---- COMMERCIALBRAND ----> */

/* TASKTYPE */
export const SET_TASKTYPE_LIST = "SET_TASKTYPE_LIST";
export const ADD_NEW_TASKTYPE = "ADD_NEW_TASKTYPE";
export const UPDATE_TASKTYPE = "UPDATE_TASKTYPE";
export const DELETE_TASKTYPE = "DELETE_TASKTYPE";
export const OPEN_TASKTYPE_FORM = "OPEN_TASKTYPE_FORM";
export const CLOSE_TASKTYPE_FORM = "CLOSE_TASKTYPE_FORM";
export const EDIT_SELECTED_TASKTYPE = "EDIT_SELECTED_TASKTYPE";
export const TASKTYPE_FORM_TOOGLE_LOADING = "TASKTYPE_FORM_TOOGLE_LOADING";
/* <---- TASKTYPE ----> */

/* PROVIDER */
export const SET_PROVIDER_LIST = "SET_PROVIDER_LIST";
export const ADD_NEW_PROVIDER = "ADD_NEW_PROVIDER";
export const UPDATE_PROVIDER = "UPDATE_PROVIDER";
export const DELETE_PROVIDER = "DELETE_PROVIDER";
export const OPEN_PROVIDER_FORM = "OPEN_PROVIDER_FORM";
export const CLOSE_PROVIDER_FORM = "CLOSE_PROVIDER_FORM";
export const EDIT_SELECTED_PROVIDER = "EDIT_SELECTED_PROVIDER";
export const PROVIDER_FORM_TOOGLE_LOADING = "PROVIDER_FORM_TOOGLE_LOADING";
/* <---- PROVIDER ----> */

/* REPLACEMENT */
export const SET_REPLACEMENT_LIST = "SET_REPLACEMENT_LIST";
export const ADD_NEW_REPLACEMENT = "ADD_NEW_REPLACEMENT";
export const UPDATE_REPLACEMENT = "UPDATE_REPLACEMENT";
export const DELETE_REPLACEMENT = "DELETE_REPLACEMENT";
export const OPEN_REPLACEMENT_FORM = "OPEN_REPLACEMENT_FORM";
export const CLOSE_REPLACEMENT_FORM = "CLOSE_REPLACEMENT_FORM";
export const EDIT_SELECTED_REPLACEMENT = "EDIT_SELECTED_REPLACEMENT";
export const REPLACEMENT_FORM_TOOGLE_LOADING = "REPLACEMENT_FORM_TOOGLE_LOADING";
/* <---- REPLACEMENT ----> */

/* PRODUCTTYPE */
export const SET_PRODUCTTYPE_LIST = "SET_PRODUCTTYPE_LIST";
export const ADD_NEW_PRODUCTTYPE = "ADD_NEW_PRODUCTTYPE";
export const UPDATE_PRODUCTTYPE = "UPDATE_PRODUCTTYPE";
export const DELETE_PRODUCTTYPE = "DELETE_PRODUCTTYPE";
export const OPEN_PRODUCTTYPE_FORM = "OPEN_PRODUCTTYPE_FORM";
export const CLOSE_PRODUCTTYPE_FORM = "CLOSE_PRODUCTTYPE_FORM";
export const EDIT_SELECTED_PRODUCTTYPE = "EDIT_SELECTED_PRODUCTTYPE";
export const PRODUCTTYPE_FORM_TOOGLE_LOADING = "PRODUCTTYPE_FORM_TOOGLE_LOADING";
/* <---- PRODUCTTYPE ----> */

/* FAMILY */
export const SET_FAMILY_LIST = "SET_FAMILY_LIST";
export const ADD_NEW_FAMILY = "ADD_NEW_FAMILY";
export const UPDATE_FAMILY = "UPDATE_FAMILY";
export const DELETE_FAMILY = "DELETE_FAMILY";
export const OPEN_FAMILY_FORM = "OPEN_FAMILY_FORM";
export const CLOSE_FAMILY_FORM = "CLOSE_FAMILY_FORM";
export const EDIT_SELECTED_FAMILY = "EDIT_SELECTED_FAMILY";
export const FAMILY_FORM_TOOGLE_LOADING = "FAMILY_FORM_TOOGLE_LOADING";
/* <---- FAMILY ----> */

/* VARIETY */
export const SET_VARIETY_LIST = "SET_VARIETY_LIST";
export const ADD_NEW_VARIETY = "ADD_NEW_VARIETY";
export const UPDATE_VARIETY = "UPDATE_VARIETY";
export const DELETE_VARIETY = "DELETE_VARIETY";
export const OPEN_VARIETY_FORM = "OPEN_VARIETY_FORM";
export const CLOSE_VARIETY_FORM = "CLOSE_VARIETY_FORM";
export const EDIT_SELECTED_VARIETY = "EDIT_SELECTED_VARIETY";
export const VARIETY_FORM_TOOGLE_LOADING = "VARIETY_FORM_TOOGLE_LOADING";
/* <---- VARIETY ----> */

/* CROP */
export const SET_CROP_LIST = "SET_CROP_LIST";
export const ADD_NEW_CROP = "ADD_NEW_CROP";
export const UPDATE_CROP = "UPDATE_CROP";
export const DELETE_CROP = "DELETE_CROP";
export const OPEN_CROP_FORM = "OPEN_CROP_FORM";
export const CLOSE_CROP_FORM = "CLOSE_CROP_FORM";
export const EDIT_SELECTED_CROP = "EDIT_SELECTED_CROP";
export const CROP_FORM_TOOGLE_LOADING = "CROP_FORM_TOOGLE_LOADING";
/* <---- CROP ----> */

/* WORK */
export const SET_WORK_LIST = "SET_WORK_LIST";
export const ADD_NEW_WORK = "ADD_NEW_WORK";
export const UPDATE_WORK = "UPDATE_WORK";
export const DELETE_WORK = "DELETE_WORK";
export const OPEN_WORK_FORM = "OPEN_WORK_FORM";
export const CLOSE_WORK_FORM = "CLOSE_WORK_FORM";
export const EDIT_SELECTED_WORK = "EDIT_SELECTED_WORK";
export const WORK_FORM_TOOGLE_LOADING = "WORK_FORM_TOOGLE_LOADING";
/* <---- WORK ----> */

/* TASK */
export const SET_TASK_LIST = "SET_TASK_LIST";
export const ADD_NEW_TASK = "ADD_NEW_TASK";
export const UPDATE_TASK = "UPDATE_TASK";
export const DELETE_TASK = "DELETE_TASK";
export const OPEN_TASK_FORM = "OPEN_TASK_FORM";
export const CLOSE_TASK_FORM = "CLOSE_TASK_FORM";
export const EDIT_SELECTED_TASK = "EDIT_SELECTED_TASK";
export const TASK_FORM_TOOGLE_LOADING = "TASK_FORM_TOOGLE_LOADING";
/* <---- TASK ----> */

/* ADVISER */
export const SET_ADVISER_LIST = "SET_ADVISER_LIST";
export const ADD_NEW_ADVISER = "ADD_NEW_ADVISER";
export const UPDATE_ADVISER = "UPDATE_ADVISER";
export const DELETE_ADVISER = "DELETE_ADVISER";
export const OPEN_ADVISER_FORM = "OPEN_ADVISER_FORM";
export const CLOSE_ADVISER_FORM = "CLOSE_ADVISER_FORM";
export const EDIT_SELECTED_ADVISER = "EDIT_SELECTED_ADVISER";
export const ADVISER_FORM_TOOGLE_LOADING = "ADVISER_FORM_TOOGLE_LOADING";
/* <---- ADVISER ----> */

/* SEED */
export const SET_SEED_LIST = "SET_SEED_LIST";
export const ADD_NEW_SEED = "ADD_NEW_SEED";
export const UPDATE_SEED = "UPDATE_SEED";
export const DELETE_SEED = "DELETE_SEED";
export const OPEN_SEED_FORM = "OPEN_SEED_FORM";
export const CLOSE_SEED_FORM = "CLOSE_SEED_FORM";
export const EDIT_SELECTED_SEED = "EDIT_SELECTED_SEED";
export const SEED_FORM_TOOGLE_LOADING = "SEED_FORM_TOOGLE_LOADING";
/* <---- SEED ----> */

/* TOOL */
export const SET_TOOL_LIST = "SET_TOOL_LIST";
export const ADD_NEW_TOOL = "ADD_NEW_TOOL";
export const UPDATE_TOOL = "UPDATE_TOOL";
export const DELETE_TOOL = "DELETE_TOOL";
export const OPEN_TOOL_FORM = "OPEN_TOOL_FORM";
export const CLOSE_TOOL_FORM = "CLOSE_TOOL_FORM";
export const EDIT_SELECTED_TOOL = "EDIT_SELECTED_TOOL";
export const TOOL_FORM_TOOGLE_LOADING = "TOOL_FORM_TOOGLE_LOADING";
/* <---- TOOL ----> */

/* PRODUCT */
export const SET_PRODUCT_LIST = "SET_PRODUCT_LIST";
export const ADD_NEW_PRODUCT = "ADD_NEW_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const OPEN_PRODUCT_FORM = "OPEN_PRODUCT_FORM";
export const CLOSE_PRODUCT_FORM = "CLOSE_PRODUCT_FORM";
export const EDIT_SELECTED_PRODUCT = "EDIT_SELECTED_PRODUCT";
export const PRODUCT_FORM_TOOGLE_LOADING = "PRODUCT_FORM_TOOGLE_LOADING";
/* <---- PRODUCT ----> */

/* EXPLOITATION */
export const SET_EXPLOITATION_LIST = "SET_EXPLOITATION_LIST";
export const ADD_NEW_EXPLOITATION = "ADD_NEW_EXPLOITATION";
export const UPDATE_EXPLOITATION = "UPDATE_EXPLOITATION";
export const DELETE_EXPLOITATION = "DELETE_EXPLOITATION";
export const OPEN_EXPLOITATION_FORM = "OPEN_EXPLOITATION_FORM";
export const CLOSE_EXPLOITATION_FORM = "CLOSE_EXPLOITATION_FORM";
export const EDIT_SELECTED_EXPLOITATION = "EDIT_SELECTED_EXPLOITATION";
export const EXPLOITATION_FORM_TOOGLE_LOADING = "EXPLOITATION_FORM_TOOGLE_LOADING";
/* <---- EXPLOITATION ----> */

/* PLOT */
export const SET_PLOT_LIST = "SET_PLOT_LIST";
export const ADD_NEW_PLOT = "ADD_NEW_PLOT";
export const UPDATE_PLOT = "UPDATE_PLOT";
export const DELETE_PLOT = "DELETE_PLOT";
export const OPEN_PLOT_FORM = "OPEN_PLOT_FORM";
export const CLOSE_PLOT_FORM = "CLOSE_PLOT_FORM";
export const EDIT_SELECTED_PLOT = "EDIT_SELECTED_PLOT";
export const PLOT_FORM_TOOGLE_LOADING = "PLOT_FORM_TOOGLE_LOADING";
/* <---- PLOT ----> */

/* ENCLOSURE */
export const SET_ENCLOSURE_LIST = "SET_ENCLOSURE_LIST";
export const ADD_NEW_ENCLOSURE = "ADD_NEW_ENCLOSURE";
export const UPDATE_ENCLOSURE = "UPDATE_ENCLOSURE";
export const DELETE_ENCLOSURE = "DELETE_ENCLOSURE";
export const OPEN_ENCLOSURE_FORM = "OPEN_ENCLOSURE_FORM";
export const CLOSE_ENCLOSURE_FORM = "CLOSE_ENCLOSURE_FORM";
export const EDIT_SELECTED_ENCLOSURE = "EDIT_SELECTED_ENCLOSURE";
export const ENCLOSURE_FORM_TOOGLE_LOADING = "ENCLOSURE_FORM_TOOGLE_LOADING";
/* <---- ENCLOSURE ----> */

/* ROLE */
export const SET_ROLE_LIST = "SET_ROLE_LIST";
export const ADD_NEW_ROLE = "ADD_NEW_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";
export const OPEN_ROLE_FORM = "OPEN_ROLE_FORM";
export const CLOSE_ROLE_FORM = "CLOSE_ROLE_FORM";
export const EDIT_SELECTED_ROLE = "EDIT_SELECTED_ROLE";
export const ROLE_FORM_TOOGLE_LOADING = "ROLE_FORM_TOOGLE_LOADING";
/* <---- ROLE ----> */

/* WORKSHIFT */
export const SET_WORKSHIFT_LIST = "SET_WORKSHIFT_LIST";
export const ADD_NEW_WORKSHIFT = "ADD_NEW_WORKSHIFT";
export const UPDATE_WORKSHIFT = "UPDATE_WORKSHIFT";
export const DELETE_WORKSHIFT = "DELETE_WORKSHIFT";
export const OPEN_WORKSHIFT_FORM = "OPEN_WORKSHIFT_FORM";
export const CLOSE_WORKSHIFT_FORM = "CLOSE_WORKSHIFT_FORM";
export const EDIT_SELECTED_WORKSHIFT = "EDIT_SELECTED_WORKSHIFT";
export const WORKSHIFT_FORM_TOOGLE_LOADING = "WORKSHIFT_FORM_TOOGLE_LOADING";
/* <---- WORKSHIFT ----> */

/* LEAVE */
export const SET_LEAVE_LIST = "SET_LEAVE_LIST";
export const ADD_NEW_LEAVE = "ADD_NEW_LEAVE";
export const UPDATE_LEAVE = "UPDATE_LEAVE";
export const DELETE_LEAVE = "DELETE_LEAVE";
export const OPEN_LEAVE_FORM = "OPEN_LEAVE_FORM";
export const CLOSE_LEAVE_FORM = "CLOSE_LEAVE_FORM";
export const EDIT_SELECTED_LEAVE = "EDIT_SELECTED_LEAVE";
export const LEAVE_FORM_TOOGLE_LOADING = "LEAVE_FORM_TOOGLE_LOADING";
/* <---- LEAVE ----> */

/* DOSIFICATIONPRODUCT */
export const SET_DOSIFICATIONPRODUCT_LIST = "SET_DOSIFICATIONPRODUCT_LIST";
export const ADD_NEW_DOSIFICATIONPRODUCT = "ADD_NEW_DOSIFICATIONPRODUCT";
export const UPDATE_DOSIFICATIONPRODUCT = "UPDATE_DOSIFICATIONPRODUCT";
export const DELETE_DOSIFICATIONPRODUCT = "DELETE_DOSIFICATIONPRODUCT";
export const OPEN_DOSIFICATIONPRODUCT_FORM = "OPEN_DOSIFICATIONPRODUCT_FORM";
export const CLOSE_DOSIFICATIONPRODUCT_FORM = "CLOSE_DOSIFICATIONPRODUCT_FORM";
export const EDIT_SELECTED_DOSIFICATIONPRODUCT =
  "EDIT_SELECTED_DOSIFICATIONPRODUCT";
export const DOSIFICATIONPRODUCT_FORM_TOOGLE_LOADING =
  "DOSIFICATIONPRODUCT_FORM_TOOGLE_LOADING";
/* <---- DOSIFICATIONPRODUCT ----> */

/* DOSIFICATIONCONFIG */
export const SET_DOSIFICATIONCONFIG_LIST = "SET_DOSIFICATIONCONFIG_LIST";
export const ADD_NEW_DOSIFICATIONCONFIG = "ADD_NEW_DOSIFICATIONCONFIG";
export const UPDATE_DOSIFICATIONCONFIG = "UPDATE_DOSIFICATIONCONFIG";
export const DELETE_DOSIFICATIONCONFIG = "DELETE_DOSIFICATIONCONFIG";
export const OPEN_DOSIFICATIONCONFIG_FORM = "OPEN_DOSIFICATIONCONFIG_FORM";
export const CLOSE_DOSIFICATIONCONFIG_FORM = "CLOSE_DOSIFICATIONCONFIG_FORM";
export const EDIT_SELECTED_DOSIFICATIONCONFIG =
  "EDIT_SELECTED_DOSIFICATIONCONFIG";
export const DOSIFICATIONCONFIG_FORM_TOOGLE_LOADING =
  "DOSIFICATIONCONFIG_FORM_TOOGLE_LOADING";
/* <---- DOSIFICATIONCONFIG ----> */

/* OPCUA */
export const SET_OPCUA_LIST = "SET_OPCUA_LIST";
export const ADD_NEW_OPCUA = "ADD_NEW_OPCUA";
export const UPDATE_OPCUA = "UPDATE_OPCUA";
export const DELETE_OPCUA = "DELETE_OPCUA";
export const OPEN_OPCUA_FORM = "OPEN_OPCUA_FORM";
export const CLOSE_OPCUA_FORM = "CLOSE_OPCUA_FORM";
export const EDIT_SELECTED_OPCUA = "EDIT_SELECTED_OPCUA";
export const OPCUA_FORM_TOOGLE_LOADING = "OPCUA_FORM_TOOGLE_LOADING";
/* <---- OPCUA ----> */

/* EXPENSE */
export const SET_EXPENSE_LIST = "SET_EXPENSE_LIST";
export const ADD_NEW_EXPENSE = "ADD_NEW_EXPENSE";
export const UPDATE_EXPENSE = "UPDATE_EXPENSE";
export const DELETE_EXPENSE = "DELETE_EXPENSE";
export const OPEN_EXPENSE_FORM = "OPEN_EXPENSE_FORM";
export const CLOSE_EXPENSE_FORM = "CLOSE_EXPENSE_FORM";
export const EDIT_SELECTED_EXPENSE = "EDIT_SELECTED_EXPENSE";
export const EXPENSE_FORM_TOOGLE_LOADING = "EXPENSE_FORM_TOOGLE_LOADING";
/* <---- EXPENSE ----> */

/* ESTABLISHMENTINFO */
export const SET_ESTABLISHMENTINFO = "SET_ESTABLISHMENTINFO";
export const OPEN_ESTABLISHMENTINFO_FORM = "OPEN_ESTABLISHMENTINFO_FORM";
export const CLOSE_ESTABLISHMENTINFO_FORM = "CLOSE_ESTABLISHMENTINFO_FORM";
export const EDIT_SELECTED_ESTABLISHMENTINFO =
  "EDIT_SELECTED_ESTABLISHMENTINFO";
export const ESTABLISHMENTINFO_FORM_TOOGLE_LOADING =
  "ESTABLISHMENTINFO_FORM_TOOGLE_LOADING";
/* <---- ESTABLISHMENTINFO ----> */

/* IMPUTHOUR */
export const SET_IMPUTHOUR_LIST = "SET_IMPUTHOUR_LIST";
export const ADD_NEW_IMPUTHOUR = "ADD_NEW_IMPUTHOUR";
export const UPDATE_IMPUTHOUR = "UPDATE_IMPUTHOUR";
export const DELETE_IMPUTHOUR = "DELETE_IMPUTHOUR";
export const OPEN_IMPUTHOUR_FORM = "OPEN_IMPUTHOUR_FORM";
export const CLOSE_IMPUTHOUR_FORM = "CLOSE_IMPUTHOUR_FORM";
export const EDIT_SELECTED_IMPUTHOUR = "EDIT_SELECTED_IMPUTHOUR";
export const IMPUTHOUR_FORM_TOOGLE_LOADING = "IMPUTHOUR_FORM_TOOGLE_LOADING";
/* <---- IMPUTHOUR ----> */

/* TIMEREQUEST */
export const SET_TIMEREQUEST_LIST = "SET_TIMEREQUEST_LIST";
export const ADD_NEW_TIMEREQUEST = "ADD_NEW_TIMEREQUEST";
export const UPDATE_TIMEREQUEST = "UPDATE_TIMEREQUEST";
export const DELETE_TIMEREQUEST = "DELETE_TIMEREQUEST";
export const OPEN_TIMEREQUEST_FORM = "OPEN_TIMEREQUEST_FORM";
export const CLOSE_TIMEREQUEST_FORM = "CLOSE_TIMEREQUEST_FORM";
export const EDIT_SELECTED_TIMEREQUEST = "EDIT_SELECTED_TIMEREQUEST";
export const TIMEREQUEST_FORM_TOOGLE_LOADING =
  "TIMEREQUEST_FORM_TOOGLE_LOADING";
/* <---- TIMEREQUEST ----> */

/* HOLIDAYREQUEST */
export const SET_HOLIDAYREQUEST_LIST = "SET_HOLIDAYREQUEST_LIST";
export const ADD_NEW_HOLIDAYREQUEST = "ADD_NEW_HOLIDAYREQUEST";
export const UPDATE_HOLIDAYREQUEST = "UPDATE_HOLIDAYREQUEST";
export const DELETE_HOLIDAYREQUEST = "DELETE_HOLIDAYREQUEST";
export const OPEN_HOLIDAYREQUEST_FORM = "OPEN_HOLIDAYREQUEST_FORM";
export const CLOSE_HOLIDAYREQUEST_FORM = "CLOSE_HOLIDAYREQUEST_FORM";
export const EDIT_SELECTED_HOLIDAYREQUEST = "EDIT_SELECTED_HOLIDAYREQUEST";
export const HOLIDAYREQUEST_FORM_TOOGLE_LOADING =
  "HOLIDAYREQUEST_FORM_TOOGLE_LOADING";
/* <---- HOLIDAYREQUEST ----> */

/* ALERT */
export const SET_ALERT_LIST = "SET_ALERT_LIST";
export const ADD_NEW_ALERT = "ADD_NEW_ALERT";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const OPEN_ALERT_FORM = "OPEN_ALERT_FORM";
export const CLOSE_ALERT_FORM = "CLOSE_ALERT_FORM";
export const EDIT_SELECTED_ALERT = "EDIT_SELECTED_ALERT";
export const ALERT_FORM_TOOGLE_LOADING = "ALERT_FORM_TOOGLE_LOADING";
/* <---- ALERT ----> */

/* EPI */
export const SET_EPI_LIST = "SET_EPI_LIST";
export const ADD_NEW_EPI = "ADD_NEW_EPI";
export const UPDATE_EPI = "UPDATE_EPI";
export const DELETE_EPI = "DELETE_EPI";
export const OPEN_EPI_FORM = "OPEN_EPI_FORM";
export const CLOSE_EPI_FORM = "CLOSE_EPI_FORM";
export const EDIT_SELECTED_EPI = "EDIT_SELECTED_EPI";
export const EPI_FORM_TOOGLE_LOADING = "EPI_FORM_TOOGLE_LOADING";
/* <---- EPI ----> */

/* DOWNLOADDOCUMENT */
export const SET_DOWNLOADDOCUMENT_LIST = "SET_DOWNLOADDOCUMENT_LIST";
/* <---- DOWNLOADDOCUMENT ----> */

/* SIGN */
export const OPEN_SIGN_FORM = "OPEN_SIGN_FORM";
export const CLOSE_SIGN_FORM = "CLOSE_SIGN_FORM";
/* <---- SIGN ----> */

export const EXPENSESTYPES = [
  { label: "Ticket", value: "TICKET", color: "pink" },
  { label: "Factura", value: "INVOICE", color: "cyan" },
  { label: "Kilometraje", value: "MILEAGE", color: "volcano" },
  { label: "H. extras", value: "HOURS", color: "green" },
];

export const EXPLOTATIONTYPES = [
  { label: "Arrendamiento", value: "LEASE", color: "pink" },
  { label: "Propiedad", value: "PROPERTY", color: "cyan" },
  { label: "Otros", value: "OTHERS", color: "volcano" },
];

export const SINGSTATUSTYPE = [
  { label: "Triste", value: 0, color: "pink" },
  { label: "Normal", value: 1, color: "cyan" },
  { label: "Contento", value: 2, color: "volcano" },
];

export const PENDING_STATUS = "PENDING";
export const INPROCESS_STATUS = "INPROCESS";
export const FINISHED_STATUS = "FINISHED";

export const FILTERLIST = [
  { label: "Todos", value: 0, },
  { label: "Explotaciones", value: 1, },
  { label: "Parcelas", value: 2, },
  { label: "Recintos", value: 3 },
]

export const STATUS = [
  {
    label: "Pendiente",
    value: PENDING_STATUS,
    color: "volcano",
    icon: <PendingTaskIcon />,
  },
  {
    label: "En proceso",
    value: INPROCESS_STATUS,
    color: "processing",
    icon: <InProcessTaskIcon />,
  },
  {
    label: "Finalizada",
    value: FINISHED_STATUS,
    color: "success",
    icon: <FinishedTaskIcon />,
  },
];
